import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import { injectIntl } from 'react-intl';
import SearchBarAnimation from 'Components/SearchBarAnimation/SearchBarAnimation';

export const SearchLabel = ({
    searchTerm,
    locationName,
    locationType,
    isHome,
    isCurrentGPSLocation
}) => {
    const locationsWithoutRelaxation = ['COUNTRY', 'STATE'];
    const values = { locationName };
    let translationKeyWithTerm = 'placeholderSearchInputNearYou';
    let translationKeyWithoutTerm = 'placeholderSearchInputAllAdsNearYou';

    if (!isCurrentGPSLocation) {
        translationKeyWithTerm = locationsWithoutRelaxation.includes(locationType)
            ? 'placeholderSearchInputIn'
            : 'placeholderSearchInputNear';
        translationKeyWithoutTerm = locationsWithoutRelaxation.includes(locationType)
            ? 'placeholderSearchInputAllAdsIn'
            : 'placeholderSearchInputAllAdsNear';
    }

    if (searchTerm) {
        return (
            <Translation id={ translationKeyWithTerm } values={ values }>
                {
                    formattedVal => (
                        <span><b>{searchTerm}</b> {formattedVal}</span>
                    )
                }
            </Translation>
        );
    }
    if (locationName && !isHome) {
        return <Translation id={ translationKeyWithoutTerm } values={ values } />;
    }

    return <SearchBarAnimation />;
};

SearchLabel.propTypes = {
    searchTerm: PropTypes.string,
    locationName: PropTypes.string,
    locationType: PropTypes.string,
    isHome: PropTypes.bool,
    isCurrentGPSLocation: PropTypes.bool
};

export default injectIntl(SearchLabel);
