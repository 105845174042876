export const TRANSLATIONS = {
    clickToCall: 'click_to_call',
    receiveCallFromBuyers: 'receive_calls_from_buyers',
    accountMergingPostingFeedback: 'account_merging_posting_feedback',
    copiedToClipboard: 'copiedToClipboard',
    markAsSold: 'markAsSold',
    adExpirationRepublishButton: 'ad_expiration_republish_button',
    featureAdButton: 'featureAdButton',
    or: 'or',
    someoneFromOutside: 'someoneFromOutside',
    soldOutsideOlx: 'soldOutsideOlx',
    soldOutsideText: 'soldOutsideText',
    soldOutsideBtnLabel: 'soldOutsideBtnLabel',
    selectBuyer: 'selectBuyer',
    backButtonSI: 'backButtonSI'
};
export const noChatsFooter = {
    letgo: 'noChatsFooterLetgo',
    olx: 'noChatsFooter'
};

export const noChatsFooterBuying = {
    letgo: 'noChatsFooterBuyingLetgo',
    olx: 'noChatsFooterBuying'
};

export const noChatsText = {
    letgo: 'noChatsTextLetgo',
    olx: 'noChatsText'
};

export const noTransactionChatsText = {
    header: {
        id: 'noChatsTransactionText',
        defaultMessage: 'Haven’t talked to anyone yet?'
    },
    footer: {
        id: 'noChatsTransactionFooter',
        defaultMessage: 'Find a vehicle you like and start conversation!'
    },
    buttonText: {
        id: 'exploreButtonTitle',
        defaultMessage: 'Explore all cars'
    }
};

export const sellYourStuff = 'sellYourStuff';
export const browseListings = 'browseListings';
export const listingAdAutoboostadLabel = 'listing_ad_autoboostad_label';
export const listingAdFeaturedLabel = 'listing_ad_featured_label';
export const unfollowProfile = 'unfollowProfile';
export const followProfile = 'followProfile';
export const followers = 'followers';
export const following = 'following';

export const consentsTextsTitle = {
    title: 'consent_page_login_flow_title_letgo'
};

export const consentsTextsSubtitle = {
    letgo: 'consent_page_login_flow_subtitle_letgo',
    olx: 'consent_page_login_flow_subtitle'
};

export const signUpPrivacyTexts = {
    loginPrivacyDescLetgo: 'loginPrivacyDescLetgo',
    loginPrivacyDesc1: 'loginPrivacyDesc1',
    loginEnterPhone: 'login_enter_phone_privacy',
    loginEnterEmail: 'login_email_enter_phone_privacy'
};
export const TRANSLATIONS_PRIVATE_PROFILE = {
    USER_VERIFIED_WITH: 'userVerifiedWith',
    EDIT_PROFILE: 'editProfile',
    MY_ACCOUNT_VIEW_PROFILE: 'my_account_view_profile'
};

export const MESSAGES_PRIVATE_PROFILE = {
    EDIT_PROFILE: 'Edit profile'
};

export const BILLING_FORM_FIELDS = {
    REQUIRED_FIELD: 'requiredFields',
    INVALID_FORMAT: 'invalidFormat'
};

export const CHOOSE_PACKAGE = {
    VIEWS: 'views',
    DAYS_FEATURED: 'days_featured',
    BOOST_TO_TOP_VAL: 'boost_to_top_val',
    MY_ADS_MODERATION_BTN: 'my_ads_moderation_button_text',
    SELL_FASTER_HEADING: 'sell_faster_heading',
    SELL_FASTER: 'sell_faster',
    BUY_PKG_FA: 'buyPkgSeeExampleFaProposition',
    BUY_PKG_BTT: 'buyPkgSeeExampleBttProposition',
    PREVIEW_PACKAGE_TEXT: 'preview_package_text',
    SEE_FEATURED_AD: 'see_featured_ad',
    SEE_BOOST_TO_TOP: 'see_boost_to_top',
    SELL_MORE: 'sell_more',
    POST_MORE_ADS: 'post_more_ads',
    SHOW_ME_PACKAGES: 'show_me_packages',
    EXAMPLE_FA: 'example_FA',
    EXAMPLE_BTT: 'example_BTT',
    UNDERSTOOD: 'understood',
    FEATURED_AD: 'featured_ad',
    CHOOSEPKG_FA: 'choosePkg_FA',
    CHOOSEPKG_BTT: 'choosePkg_BTT',
    CHOOSEPKG_AUTOBOOST: 'choosePkg_AUTOBOOST',
    ATTRACT_MORE_BUYERS: 'attract_more_buyers',
    SUBHEADING_FA: 'subHeading_FA',
    SUBHEADING_BTT: 'subHeading_BTT',
    PREVIEW: 'preview',
    SEE_EXAMPLE: 'see_example',
    FEATURED_MODAL_HEADER_FA: 'featured_modal_header_FA',
    FEATURED_MODAL_HEADER_BTT: 'featured_modal_header_BTT',
    HOW_IT_WORKS: 'how_it_works',
    AD_APPEARNCE_FA: 'ad_appearence_FA',
    AD_APPEARNCE_2_FA: 'ad_appearence_2_FA',
    AD_APPEARNCE_BTT: 'ad_appearence_BTT',
    AD_APPEARNCE_2_BTT: 'ad_appearence_2_BTT',
    BUY_PKG_SEE_EXAMPLE: 'buyPkgSeeExample',
    EXAMPLE_: 'example_',
    FEATURED_MODAL_HEADER: 'featured_modal_header_',
    AD_APPEARNCE_: 'ad_appearence_',
    AD_APPEARNCE_2_: 'ad_appearence_2_',
    CHOOSE_PKG: 'choosePkg_',
    BUY_PKG: 'buyPkg_',
    BUY_PKG_WHAT_NEXT: 'buyPkgWhatNext',
    PAY: 'Pay'
};

export const VIDEO_PACKAGE = {
    upload_youtube_link: 'Please upload a single valid youtube link',
    add_youtube_link: 'Add Youtube link',
    video_added_successfully: 'Video added successfully',
    video_adding_error: 'Unable to add video try again after some time',
    error_message: 'Some error occured please try after some time',
    no_video_package: "You don't have video package for this category",
    support_message: 'Please reach out to support to buy more video package',
    support_helpline: 'Support Helpline',
    support_number: 'Number:- 1860-258-3333',
    support_email: 'Email Address:- support@olx.in'
};

export const MONET_SUPPORT = {
    call_monet: 'Call us on - 1860-258-3333',
    email_monet: 'Email on - support@olx.in'
};

export const PTT_PACKAGE = {
    date: 'Date:',
    package_ptt: 'Package: Pin to Top',
    ptt_today: 'was used successfully today',
    ptt_future: 'will be applied on'
};

export const DASHBOARD = {
    seller_dashboard: 'Seller Dashboard',
    price_recommendation: 'Price Recommendation',
    your_ad_price: 'Your Ad Price:- ',
    price_recommendation_for: ' Price recommendation for ',
    ad_title: 'Ad Title:- ',
    suggested_title: 'Suggested Title:- ',
    ad_description: 'Ad Description:- ',
    suggested_description: 'Suggested Description:- ',
    replies_trend: 'Monthly Replies Trend',
    num_images_trend: 'Monthly Number of Images Trend',
    title_and_description_length: 'Title and Description Length',
    title_length: 'Title Length',
    description_length: 'Description Length',
    suggested_keywords: 'Suggested Keywords for your category :- ',
    conversion_metrics: 'Conversion Metrics',
    inquiries_to_sold: 'Monthly Inquiries to Sold'
};

export const PACKAGE_RENEWAL_NUDGE = {
    renew: 'Renew',
    packages_expired: 'Your package expired',
    packages_expired_multi: 'Your packages expired',
    packages_expiring: 'Your package is expiring',
    packages_expiring_multi: 'Your packages are expiring',
    package_date: 'on {value}'
};

export const EMPTY_CART = {
    oops: 'Oops!!',
    msg: 'Hey, Your Cart is empty. Please add any packages to start selling faster',
    add_package: 'Add Packages'
};

export const EXPLORE_ALL_NUDGE = {
    widget_discover_more: 'Want to discover more packages?'
};

export const COUPON = {
    coupon_tag_available: 'Coupon available',
    saving_upto: 'You are saving up to {value}'
};
