import React from 'react';
import PropTypes from 'prop-types';
import css from './NavigationHeader.scss';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import Button from 'Components/Button/Button';
import classnames from 'classnames';
import withDirection from 'HOCs/withDirection/withDirection';
import { compose } from 'redux';
import withConfig from 'HOCs/withConfig/withConfig';

export const NavigationHeader = ({ classes, children, showIcon, iconType, onClickBack, title, rightSideText, onClickRightSideText, haveShadow, direction }) => {
    return (
        <header
            className={ classnames(css.header, classes, { [css.shadow]: haveShadow }) }
            data-aut-id="navigationHeader"
        >
            {
                children
                || (<div className={ classnames(css.navigationHeader) }>
                    { showIcon
                            && <span className={ css.icon }>
                                <NewIconButton
                                    direction={ direction }
                                    icon={ iconType }
                                    onClick={ onClickBack }
                                    data-aut-id="overlayHeaderIcon"
                                />
                            </span>
                    }
                    { title
                            && <div className={ classnames(css.title) } data-aut-id="navigation-header-title">
                                { title }
                            </div> }
                    { rightSideText
                            && <Button
                                type="tertiaryBtn"
                                onClick={ onClickRightSideText }
                                className={ css.rightSideText }
                                data-aut-id="rightSideText">
                                <span>{ rightSideText }</span>
                            </Button>
                    }
                </div>)
            }
        </header>
    );
};

NavigationHeader.propTypes = {
    title: PropTypes.node,
    onClickBack: PropTypes.func,
    rightSideText: PropTypes.node,
    classes: PropTypes.string,
    onClickRightSideText: PropTypes.func,
    showIcon: PropTypes.bool,
    iconType: PropTypes.string,
    children: PropTypes.node,
    haveShadow: PropTypes.bool,
    direction: PropTypes.oneOf(['ltr', 'rtl']),
    config: PropTypes.shape({
        get: PropTypes.func
    })
};

NavigationHeader.defaultProps = {
    title: '',
    rightSideText: '',
    onClickBack: () => {},
    onClickRightSideText: () => {},
    classes: '',
    showIcon: true,
    iconType: 'back',
    children: '',
    haveShadow: true,
    direction: 'ltr'
};

export default compose(
    withConfig,
    withDirection
)(NavigationHeader);
