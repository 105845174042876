import { urls as itemsUrls } from 'Reducers/items';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { APIVERSION } from 'Constants/items';

const getItem = itemId => (dispatch, getState, { get }) => {
    const url = itemsUrls.getItem(itemId);

    return dispatch(get(url, 'ELEMENT_ITEMS'));
};

export const BXP_REQUIRED_HEADER = { 'x-panamera-client-id': `web-${PLATFORM}` };
const getItemDetail = (itemId, version = APIVERSION.V2, lang = null, config = {}) => (dispatch, getState, { get }) => {
    const url = itemsUrls.getItemDetail(itemId, version);
    const params = lang ? { lang } : {};

    return dispatch(get(url, 'ELEMENT_ITEMS', params, BXP_REQUIRED_HEADER, { config }));
};

const trackItemViewCount = itemId => (dispatch, getState, { post }) => {
    const url = itemsUrls.trackAdView(itemId);

    return dispatch(post(url, 'ELEMENT_ITEMS')).then(res => {
        if (res && res.ok) {
            return Promise.resolve(res.data);
        }
        return Promise.reject(res);
    });
};

export {
    getItem,
    getItemDetail,
    trackItemViewCount
};
