/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */

/** External dependencies */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'panamera-react-ui';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';

/** HOCs */
import withConfig from 'HOCs/withConfig/withConfig';

/** Helpers */
import { buildURL } from 'Helpers/url';
import { isEmpty, omit } from 'Helpers/objects';
import { decodeFilters } from 'Helpers/filters';
import { scrollToTop } from 'Helpers/page';

/** Constants */
import { CARET_ICON, COLUMN_LINKS, DISABLED_SELL_FLOW_LINKS, TOP_SECTIONS_TYPE } from 'Constants/footer';

/** Selectors */
import { getCategoryById } from 'Selectors/categories';
import { locationByIdSelector } from 'Selectors/location';
import { getPopularLocationsLeftBar } from 'Selectors/popularLocations';
import { nestedCheckboxValuesSelector, checkboxGroupValuesSelector, getNestedAndSelectFilters } from 'Selectors/filters';
import { getPopularFiltersForFooter, getNestedFiltersWithChildren, getParsedAppliedFilters, getHiddenFilterIds } from 'Selectors/filtersTanak';

/** Components */
import ExpansionPanel from 'Components/ExpansionPanel/ExpansionPanel';
import CollapsableList from '../CollapsableList';
import lazyLoad from 'Components/asyncLoading/lazyLoad';
import SeoText from 'Components/Footer/SeoText.mobile';
import DisclaimerMobile from './Disclaimer.mobile';

/** Actions */
import { mapStateToCategories, getCategories } from 'Actions/categories';

/** Styles */
import css from '../Footer.scss';
import { ICON_LIKE_SIZE, ICON_MID_SIZE } from 'Helpers/statistic';
import { getBreadCrumbLinks } from 'Helpers/breadcrumb';
import Structured from 'Components/Breadcrumb/Structured';
import RelatedSearches from './RelatedSearches';
import { itemsSearchQuerySelector } from 'Selectors/items';
import withSessionLong from 'HOCs/withSessionLong/withSessionLong';
import { getLoggedUser } from 'Selectors/user';
import { addUserInfoInLinks } from 'Helpers/seoFooter';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';

const IconsColumn = lazyLoad({
    loader: () => import(/* webpackChunkName: "footer" */'../Columns/IconsColumn'),
    options: {
        rootMargin: '200px'
    }
});

const MobileAppsIcon = lazyLoad({
    loader: () => import(/* webpackChunkName: "footer" */'../Columns/MobileAppsIcon/MobileAppsIcon'),
    options: {
        rootMargin: '200px'
    }
});

export const FILTER_FOOTER = 'filter-footer-';

export class Top extends React.Component {
    static propTypes = {
        showTopMobile: PropTypes.bool.isRequired,
        sections: PropTypes.array,
        topSections: PropTypes.array,
        filters: PropTypes.array,
        popularFilters: PropTypes.array,
        nestedFiltersWithChildren: PropTypes.array,
        appliedFilters: PropTypes.object,
        brand: PropTypes.string,
        categories: PropTypes.array,
        category: PropTypes.object,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        params: PropTypes.shape({
            text: PropTypes.string,
            categoryID: PropTypes.string,
            geoID: PropTypes.string
        }).isRequired,
        geoLocation: PropTypes.object,
        location: PropTypes.object,
        locations: PropTypes.array,
        getCategories: PropTypes.func.isRequired,
        router: PropTypes.object,
        track: PropTypes.func,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        isOlxAutosLandingPage: PropTypes.bool,
        isPaidCampaign: PropTypes.bool,
        searchQuery: PropTypes.string,
        userId: PropTypes.string,
        userSessionLong: PropTypes.string,
        hiddenFilterIds: PropTypes.object,
        sessionFeatures: PropTypes.arrayOf(PropTypes.string),
        source: PropTypes.string
    };

    static defaultProps = {
        sections: [],
        topSections: [],
        filters: [],
        locations: [],
        category: {},
        brand: '',
        popularFilters: [],
        nestedFiltersWithChildren: [],
        appliedFilters: {},
        isOlxAutosLandingPage: false,
        isPaidCampaign: false,
        sessionFeatures: []
    };

    constructor(props) {
        super(props);
        this.baseUrl = props.config.get('staticAssets');
        this.theme = props.config.get('theme', 'id');
        this.siteCode = props.config.get('siteCode');
        this.isNewMobileFooter = props.config.get('isNewMobileFooter');
        this.ppcColumns = props.config.get('ppcColumns');
        this.caretIcon = CARET_ICON.RIGHT;
        this.staticAssetPath = props.config.get('staticAssets') ? props.config.get('staticAssets') : '';
    }

    componentDidMount() {
        this.props.getCategories();
    }

    getFilterInfo = (selectior, filter) => filter[selectior]

    getFilterId = this.getFilterInfo.bind(this, 'id')
    getFilterLinkTo = this.getFilterInfo.bind(this, 'to')
    getFilterName = this.getFilterInfo.bind(this, 'name')

    getCategoryLinksL2(category) {
        const { geoLocation } = this.props;

        if (category.sub_categories) {
            return category.sub_categories
                .map(_category => (
                    <li key={ _category.id }>
                        <Link
                            to={ buildURL({ category: _category, location: geoLocation }) }
                            onClick={ scrollToTop }>
                            {_category.name}
                        </Link>
                    </li>
                ));
        }
        return null;
    }

    getCategoryLinksL1(categories) {
        const { geoLocation } = this.props;

        return (
            <React.Fragment>
                {categories.map(category => {
                    const { sub_categories } = category;

                    if (sub_categories.length === 1) {
                        return (
                            <Link
                                className={ css.soloSubCategoryLink }
                                // eslint-disable-next-line react/jsx-no-bind
                                to={ buildURL({ category: sub_categories[0], location: geoLocation }) }
                                onClick={ scrollToTop }
                            >
                                <div>{category.name}</div>
                            </Link>
                        );
                    }

                    let newFooterProps = {};

                    if (this.isNewMobileFooter) {
                        newFooterProps = {
                            fatherClassName: css.nestedFather,
                            titleClassName: css.title,
                            iconClassName: css.icon,
                            iconSize: ICON_LIKE_SIZE,
                            expandedClassName: css.expanded
                        };
                    }

                    return (
                        <ExpansionPanel
                            id={ category.key }
                            key={ category.key }
                            title={ category.name }
                            containerClassName={ css.expansionPanel }
                            childrenClassName={ css.nestedChildren }
                            icon={ CARET_ICON.DOWN }
                            { ...newFooterProps } >
                            <ul className={ css.links }>
                                {this.getCategoryLinksL2(category)}
                                <li key={ category.id }>
                                    <Link
                                        to={ buildURL({ category, location: geoLocation }) }
                                        onClick={ scrollToTop }
                                        target={ category.target ? category.target : '' }
                                        rel={ category.target ? 'nofollow noopener noreferrer' : '' }>
                                        <FormattedMessage id="seeAll" />
                                    </Link>
                                </li>
                            </ul>
                        </ExpansionPanel>
                    );
                })}
            </React.Fragment>
        );
    }

    getLocationLinks = location => {
        const sessionFeatures = this.props?.sessionFeatures || [];

        return (
            <CollapsableList
                expansionKey={ location.id }
                expansionTitle={ location.title }
                listLinks={ location.list }
                itemKey={ _location => _location.id }
                linkTo={ _location => _location.to }
                linkLabel={ _location => _location.title }
                fatherStyle={ '' }
                titleStyle={ '' }
                iconStyle={ '' }
                iconSize={ ICON_MID_SIZE }
                expandedClassName={ '' }
                track={ this.props.track }
                icon={ CARET_ICON.DOWN }
                categoryId={ this.props.category?.id }
                sessionFeatures={ sessionFeatures }
            />
        );
    }

    getURL = (filters, appliedFilters = {}, abundance = {}) => {
        const { category, geoLocation: location, location: { query }, config, searchQuery } = this.props;
        const filter = {
            ...appliedFilters,
            ...filters
        };

        return abundance.count ? buildURL({
            category,
            location,
            params: {
                ...query,
                filter
            },
            search: searchQuery
        }, { useFakeLevelSlug: config.get('SEO', 'useFakeLevelSlug') }) : '';
    }

    getFilterLinks(appliedFilters, map, filter) {
        if (appliedFilters[filter.id]) {
            return filter.children ? this.getFilterLinks(appliedFilters, map, filter.children) : null;
        }

        const filterValues = map[filter.type] && map[filter.type].getValues(filter);

        if (!filterValues) {
            return null;
        }

        return (
            <CollapsableList
                key={ filter.id }
                expansionClassName={ css.nested }
                fatherStyle={ css.nestedFather }
                childrenStyle={ css.nestedChildren }
                expansionKey={ `${FILTER_FOOTER}${filter.id}` }
                expansionTitle={ filter.description }
                listLinks={ filterValues }
                itemKey={ this.getFilterId }
                linkTo={ this.getFilterLinkTo }
                linkLabel={ this.getFilterName }
                titleStyle={ '' }
                iconStyle={ '' }
                iconSize={ ICON_MID_SIZE }
                expandedClassName={ '' }
                track={ this.props.track }
                icon={ CARET_ICON.DOWN }
            />
        );
    }

    getPNRFiltersLinks() {
        const { filters, location } = this.props;
        const appliedFilters = decodeFilters(location.query.filter);

        const map = {
            nestedCheckbox: {
                getValues: filter => nestedCheckboxValuesSelector({ filter, appliedFilters, buildURL: this.getURL })
            },
            nested_select: {
                getValues: filter => checkboxGroupValuesSelector({ filter, appliedFilters, buildURL: this.getURL })
            },
            select: {
                getValues: filter => checkboxGroupValuesSelector({ filter, appliedFilters, buildURL: this.getURL })
            }
        };

        return filters.map(filter => this.getFilterLinks(appliedFilters, map, filter));
    }

    // TODO: remove pnr filters when tanak is made default
    getFiltersLinks() {
        const tanakFilters = this.getTanakFiltersLinks();

        return tanakFilters.length
            ? [
                ...tanakFilters
                // this.getViewAllFiltersComponent()
            ]
            : this.getPNRFiltersLinks();
    }

    getViewAllFiltersComponent() {
        const { location, router } = this.props;
        const url = `${location.pathname}/all-filters${location.search || ''}`;

        return (
            <div className={ `${css.panelLinkWrapper}` }
                onClick={ () => {
                    router.push(url);
                } }
            >
                <Link
                    className={ css.panelLink }
                    id="view-all-filters"
                    to={ url }
                >
                    <Translation id="view_all_filters" />
                </Link>
            </div>
        );
    }

    getNestedFilterLinks(appliedFilters, filterObj) {
        const { valuesMap, id } = filterObj;
        const parentFilterInfo = valuesMap[appliedFilters[id]];
        const parentFilters = [id];

        if (parentFilterInfo) {
            const { children } = parentFilterInfo;
            const { id: childId, values: nestedValues, label } = children;

            const filterValues = nestedValues.map(value => {
                return {
                    id: value.id,
                    name: value.label,
                    to: this.getURL(value.filters, omit(appliedFilters, parentFilters), value.abundance)
                };
            });

            return (
                <CollapsableList
                    key={ childId }
                    expansionClassName={ css.nested }
                    fatherStyle={ css.nestedFather }
                    childrenStyle={ css.nestedChildren }
                    expansionKey={ `${FILTER_FOOTER}${childId}` }
                    expansionTitle={ label }
                    listLinks={ filterValues }
                    itemKey={ this.getFilterId }
                    linkTo={ this.getFilterLinkTo }
                    linkLabel={ this.getFilterName }
                    titleStyle={ '' }
                    iconStyle={ '' }
                    iconSize={ ICON_MID_SIZE }
                    expandedClassName={ '' }
                    track={ this.props.track }
                    icon={ CARET_ICON.DOWN }
                />
            );
        }

        return null;
    }

    getPopularFiltersForLinks(filterObj, appliedFilters) {
        const { heading, values, id, nestedFilters = [] } = filterObj;

        if (this.props.hiddenFilterIds?.[id]) {
            return null;
        }
        const filterValues = values.map(value => {
            return {
                id: value.label,
                name: value.label,
                to: this.getURL(value.filters, omit(appliedFilters, nestedFilters), value.abundance)
            };
        });

        return (
            <CollapsableList
                key={ id }
                expansionClassName={ css.nested }
                fatherStyle={ css.nestedFather }
                childrenStyle={ css.nestedChildren }
                expansionKey={ `${FILTER_FOOTER}${id}` }
                expansionTitle={ heading }
                listLinks={ filterValues }
                itemKey={ this.getFilterId }
                linkTo={ this.getFilterLinkTo }
                linkLabel={ this.getFilterName }
                titleStyle={ '' }
                iconStyle={ '' }
                iconSize={ ICON_MID_SIZE }
                expandedClassName={ '' }
                track={ this.props.track }
                icon={ CARET_ICON.DOWN }
            />
        );
    }

    getTanakFiltersLinks() {
        const { appliedFilters, popularFilters, nestedFiltersWithChildren } = this.props;

        return [
            ...nestedFiltersWithChildren.map(filterObj => this.getNestedFilterLinks(appliedFilters, filterObj)),
            ...popularFilters.map(filterObj => this.getPopularFiltersForLinks(filterObj, appliedFilters))
        ];
    }

    getLocationSelected(locations) {
        for (let i = 0; i < locations.length; i++) {
            const location = locations[i];

            if (location.selected) {
                return location;
            }
            return this.getLocationSelected(location.list);
        }

        return {};
    }

    renderFilters({ filters, popularFilters, nestedFiltersWithChildren }) {
        if (!!filters.length || !!popularFilters.length || !!nestedFiltersWithChildren.length) {
            const translations = {
                filters: <Translation id="filters" />
            };

            return (
                <ExpansionPanel
                    key="filters-mobile-footer"
                    childrenClassName={ css.nestedChildren }
                    title={ translations.filters }
                    disabled={ false }
                    containerClassName={ classNames(css.expansionPanel, css.nested) }
                    icon={ CARET_ICON.DOWN }
                    fatherClassName={ '' }
                    titleClassName={ '' }
                    iconClassName={ '' }
                    iconSize={ ICON_MID_SIZE }
                    expandedClassName={ '' }
                    track={ this.props.track }>
                    {this.getFiltersLinks()}
                </ExpansionPanel>
            );
        }
        return null;
    }

    getTopLinksColumn = sections => {
        const { track, intl } = this.props;

        return sections
            .map((section, idx) => {
                switch (section.type) {
                    case TOP_SECTIONS_TYPE.BREADCRUMB: {
                        const breadcrumbLinks = getBreadCrumbLinks(section.links, intl);

                        return breadcrumbLinks ? (<React.Fragment key={ idx }>
                            <Structured links={ breadcrumbLinks } />
                            <CollapsableList
                                expansionKey={ section.id }
                                expansionTitle={ section.title }
                                listLinks={ breadcrumbLinks }
                                itemKey={ s => s.text.toLowerCase() }
                                linkTo={ s => s.href }
                                linkLabel={ s => s.text }
                                fatherStyle={ css.titleContainer }
                                titleStyle={ css.title }
                                iconStyle={ css.icon }
                                iconSize={ ICON_LIKE_SIZE }
                                expandedClassName={ css.expanded }
                                track={ track }
                                icon={ CARET_ICON.DOWN }
                            />
                        </React.Fragment>) : null;
                    }
                    case TOP_SECTIONS_TYPE.RELATED_SEARCHES: {
                        return (<React.Fragment key={ idx }>
                            <RelatedSearches
                                expansionKey={ section.id }
                                expansionTitle={ section.title }
                                itemKey={ s => s.term.toLowerCase() }
                                linkTo={ s => s.href }
                                linkLabel={ s => s.term }
                                fatherStyle={ css.titleContainer }
                                titleStyle={ css.title }
                                iconStyle={ css.icon }
                                iconSize={ ICON_LIKE_SIZE }
                                expandedClassName={ css.expanded }
                                track={ track }
                            />
                        </React.Fragment>);
                    }
                    default: return null;
                }
            });
    }

    getLinksColumns = sections => {
        const { track, isOlxAutosLandingPage, isPaidCampaign, config, userId, userSessionLong } = this.props;
        const addUserInFooterLinks = config.get('addUserInFooterLinks');

        if (this.isNewMobileFooter) {
            const sectionElements = [];

            const sectionsToShow = isPaidCampaign && this.ppcColumns ? this.ppcColumns : COLUMN_LINKS;

            return sectionsToShow.reduce((sectionElements, link, idx) => {
                const found = sections.find(s => s.id === link);

                if (found) {
                    const sellFlowEnabled = config.get('itemPostPlaceholder', 'enabled');
                    const isDisabled = sellFlowEnabled ? false : DISABLED_SELL_FLOW_LINKS.find(s => s === found.id);

                    if (!isDisabled) {
                        sectionElements.push(<React.Fragment key={ idx }>
                            <CollapsableList
                                expansionKey={ found.id }
                                expansionTitle={ found.title }
                                listLinks={ found.links }
                                itemKey={ s => s.title.toLowerCase() }
                                linkTo={ s => s.href }
                                linkLabel={ s => s.title }
                                fatherStyle={ css.titleContainer }
                                titleStyle={ css.title }
                                iconStyle={ css.icon }
                                iconSize={ ICON_LIKE_SIZE }
                                expandedClassName={ css.expanded }
                                track={ track }
                                icon={ CARET_ICON.DOWN }
                            />
                        </React.Fragment>);
                    }
                }
                return sectionElements;
            }, sectionElements);
        }

        return sections
            .filter(section => {
                return isOlxAutosLandingPage
                    && isPaidCampaign
                    && this.ppcColumns ? this.ppcColumns.includes(section.id) : COLUMN_LINKS.includes(section.id);
            })
            .map((section, idx) => {
                const links = addUserInFooterLinks
                && addUserInFooterLinks.length > 0
                && addUserInFooterLinks.indexOf(section.id) >= 0
                && addUserInfoInLinks(section.links, userSessionLong, userId)
                || section.links;

                return (<React.Fragment key={ idx }>
                    <CollapsableList
                        expansionKey={ section.id }
                        expansionTitle={ section.title }
                        listLinks={ links }
                        itemKey={ s => s.title.toLowerCase() }
                        linkTo={ s => s.href }
                        linkLabel={ s => s.title }
                        titleStyle={ css.title }
                        iconSize={ ICON_MID_SIZE } />
                </React.Fragment>);
            });
    }

    render() {
        const { sections: rawSections, brand, categories, locations, popularFilters, nestedFiltersWithChildren, showTopMobile, filters, track, isOlxAutosLandingPage, isPaidCampaign, sessionFeatures, source } = this.props;
        const locationSelected = this.getLocationSelected(locations);
        const mobileAppsSection = (rawSections || []).filter(section => section?.id === 'mobile-apps');

        if (rawSections.length === 0 || !showTopMobile) {
            return null;
        }

        let newFooterProps = {};

        if (this.isNewMobileFooter) {
            newFooterProps = {
                fatherClassName: css.titleContainer,
                titleClassName: css.title,
                iconClassName: css.icon,
                iconSize: ICON_LIKE_SIZE,
                expandedClassName: css.expanded
            };
        }

        return (
            <div className={ css.topFooter } data-aut-id="top-mobile-footer">
                { /* Only render on home page */
                    (source === 'HOME_SCREEN' || source === 'home')
                        ? <MobileAppsIcon sections={ mobileAppsSection } brand={ brand } sessionFeatures={ sessionFeatures } source={ source } />
                        : undefined
                }
                { !isOlxAutosLandingPage
                    && !isPaidCampaign
                    && categories && <ExpansionPanel
                    key="categories-L1-footer"
                    childrenClassName={ css.nestedChildren }
                    title={ this.props.intl.formatMessage({ id: 'categories' }) }
                    disabled={ false }
                    containerClassName={ classNames(css.expansionPanel, css.nested) }
                    icon={ CARET_ICON.DOWN }
                    { ...newFooterProps } >
                    {this.getCategoryLinksL1(categories)}
                </ExpansionPanel>
                }
                {!isEmpty(locationSelected) && locationSelected.list.length > 0 && this.getLocationLinks(locationSelected)}
                {<SeoText />}
                {
                    this.renderFilters({ filters, nestedFiltersWithChildren, popularFilters })
                }
                {this.getLinksColumns(rawSections)}
                <DisclaimerMobile />
                <IconsColumn sections={ (rawSections || []).filter(section => section?.id !== 'mobile-apps') } brand={ brand } track={ track } />
            </div>
        );
    }
}

const getParams = props => ({
    categoryId: props.categoryId || props.params.categoryID,
    locationId: props.locationId || props.params.geoID,
    search: props.params.text,
    params: props.location.query,
    type: props.type
});

const mapStateToProps = (state, props) => ({
    filters: getNestedAndSelectFilters(state, props),
    categories: mapStateToCategories(state),
    category: getCategoryById(state, props.params.categoryID),
    locations: getPopularLocationsLeftBar(state, getParams(props)),
    geoLocation: locationByIdSelector(state)(props.params.geoID),
    hiddenFilterIds: getHiddenFilterIds(state),
    popularFilters: getPopularFiltersForFooter(state, props.categoryId || props.params.categoryID),
    nestedFiltersWithChildren: getNestedFiltersWithChildren(state, props.categoryId || props.params.categoryID),
    appliedFilters: getParsedAppliedFilters(state, props.categoryId || props.params.categoryID),
    searchQuery: itemsSearchQuerySelector(state, props.params.text),
    userId: getLoggedUser(state).id
});

const mapDispatchToProps = { getCategories };

export default compose(
    withRouter,
    withConfig,
    injectIntl,
    withSessionLong,
    withSessionFeature,
    connect(mapStateToProps, mapDispatchToProps)
)(Top);
