import React from 'react';
import PropTypes from 'prop-types';

import { scrollToTop } from 'Helpers/page';
import ExpansionPanel from 'Components/ExpansionPanel/ExpansionPanel';
import css from './Footer.scss';
import classNames from 'classnames';
import { CARET_ICON } from 'Constants/footer';
import { Link } from 'panamera-react-ui';
import { MAIN_CATEGORY } from 'Constants/categories';

export const CollapsableList = ({
    expansionKey,
    expansionTitle,
    expansionClassName,
    fatherStyle,
    childrenStyle,
    listLinks,
    linkTo,
    linkLabel,
    itemKey,
    titleStyle,
    iconStyle,
    iconSize,
    expandedClassName,
    icon,
    categoryId
}) => {
    return (
        <ExpansionPanel
            id={ expansionKey }
            title={ expansionTitle }
            containerClassName={ expansionClassName || css.expansionPanel }
            fatherClassName={ fatherStyle || '' }
            childrenClassName={ childrenStyle || '' }
            titleClassName={ titleStyle || '' }
            iconClassName={ iconStyle || '' }
            iconSize={ iconSize }
            expandedClassName={ expandedClassName }
            icon={ icon } >
            <ul className={ classNames(css.links) }>
                {
                    listLinks.map(element => {
                        let link = (
                            <Link
                                to={ linkTo(element) }
                                onClick={ scrollToTop }>
                                {linkLabel(element)}
                            </Link>
                        );

                        if (categoryId === MAIN_CATEGORY.CARS) {
                            link = (
                                <Link
                                    to={ linkTo(element) }
                                    onClick={ scrollToTop }
                                    title={ `Used Cars in ${element.title}` }
                                >
                                    {linkLabel(element)}
                                </Link>
                            );
                        }

                        return (
                            <li key={ itemKey(element) }>
                                {element.to || element.href ? link : (
                                    <span className={ css.disabled }>
                                        {linkLabel(element)}
                                    </span>
                                )}
                            </li>
                        );
                    })
                }
            </ul>
        </ExpansionPanel>
    );
};

CollapsableList.propTypes = {
    expansionClassName: PropTypes.string,
    fatherStyle: PropTypes.string,
    childrenStyle: PropTypes.string,
    expansionKey: PropTypes.string.isRequired,
    expansionTitle: PropTypes.string.isRequired,
    listLinks: PropTypes.array.isRequired,
    linkTo: PropTypes.func.isRequired,
    itemKey: PropTypes.func.isRequired,
    linkLabel: PropTypes.func.isRequired,
    titleStyle: PropTypes.string,
    iconStyle: PropTypes.string,
    iconSize: PropTypes.number,
    expandedClassName: PropTypes.string,
    icon: PropTypes.oneOf([CARET_ICON.DOWN, CARET_ICON.RIGHT]),
    categoryId: PropTypes.string
};

CollapsableList.defaultProps = {
    icon: CARET_ICON.DOWN
};

export default CollapsableList;
