import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import Meta from '../../components/SEO/Meta';
import { mapStateToTags, getTags } from '../../actions/seo';
import { withConfig } from 'HOCs/withConfig/withConfig';
import ifConfig from 'HOCs/ifConfig/ifConfig';
import withRouter from 'HOCs/withRouter';
import { categoriesByFilterSelector, categorySelector } from 'Selectors/categories';
import { MAIN_CATEGORY } from 'Constants/categories';
import * as filtersHelper from 'Helpers/filters';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
import { itemsMetadataSelector } from 'Selectors/items';
import { LOCATION } from 'Constants/locations';
import { getLocationById } from 'Helpers/locations';
import { LOCATION_LIMIT, LOCATION_TYPE } from 'Constants/footerInterLinkData';
import { getPopularLocationsByLimit } from 'Actions/locations';

let cache = { query: {}, tags: {}};

/**
 * @param {Function} mapPropsToQuery - Maps components properties to the SEO's tags query
 * @return {ReactComponent} Decorated component
 */
export const withTags = (_module, mapPropsToQuery, { withHelmet = true } = {}) => Component => {
    const configWrapper = c => ({
        get: (...args) => args.reduce((v = {}, k) => v[k], c)
    });

    const getModule = (props = {}) => {
        return typeof _module === 'string' ? _module : _module(props);
    };

    const getCategoryFiltersById = (filters, id) => {
        let categoryFilter = { count: 0 };

        if (id) {
            for (const filter of filters) {
                if (filter.id === id) {
                    categoryFilter = filter;
                }

                const sub_categories = filter?.sub_categories || [];
                const cat = sub_categories?.find(f => f.id === id);

                if (cat) {
                    categoryFilter = cat;
                }
            }
        }

        return categoryFilter;
    };

    const getInventoryCount = (selectedLocation, itemsMetadata, categoryFilters) => {
        const isL3LocationSelected = selectedLocation?.type === LOCATION.CITY;
        const isL4LocationSelected = selectedLocation?.type === LOCATION.NEIGHBOURHOOD;

        const locationFilterFromItemsMetadata = itemsMetadata?.filters?.length > 0 ? itemsMetadata?.filters.find(f => f.id === 'location') || {} : {};
        const locationData = getLocationById(locationFilterFromItemsMetadata, selectedLocation?.id);

        const aggregatedCount = (locationData?.children || [])?.reduce((acc, curr) => acc + curr.count, 0);
        const l4LocationBasedCount = isL4LocationSelected ? locationData?.count : 0;
        const locationBasedCount = isL3LocationSelected ? aggregatedCount : l4LocationBasedCount;

        return locationBasedCount || categoryFilters?.count || 0;
    };

    const shouldAddMetadata = (filters, category, selectedLocation, flags) => {
        const { isPositionTypeFilterSelected = false, isWorkModeFilterSelected = false, isRefurbishedFilterSelected = false, decodedFilters } = filters || {};
        const { jobsH1Flag = false, mobileH1Flag = false } = flags || {};

        return (
            category?.id === MAIN_CATEGORY.CARS
            || (category?.id === MAIN_CATEGORY.JOBS && isWorkModeFilterSelected)
            || (
                jobsH1Flag && (
                    category?.id === MAIN_CATEGORY.JOBS && selectedLocation.type !== 'COUNTRY'
                    || (category?.id === MAIN_CATEGORY.JOBS && isPositionTypeFilterSelected)
                    || category?.parent_id === MAIN_CATEGORY.JOBS
                )
            )
            || (
                category?.id === MAIN_CATEGORY.MOTORCYCLES
                && (decodedFilters?.price_max || decodedFilters?.price_min)
            )
            || (
                isRefurbishedFilterSelected
                && category?.id === MAIN_CATEGORY.MOBILE_PHONES
            )
            || (
                mobileH1Flag
                && (
                    category?.id === MAIN_CATEGORY.MOBILES
                    || category?.parent_id === MAIN_CATEGORY.MOBILES
                )
            )
            || category?.id === MAIN_CATEGORY.ACS
            || category?.id === MAIN_CATEGORY.FRIDGE
        );
    };

    class WithTags extends React.Component {
        static fetchData(...args) {
            const [dispatch, renderProps, reqProps, options, store] = args;
            const module = getModule({ ...renderProps, config: configWrapper(options.config) });
            const { location, params } = renderProps;

            const decodedFilters = filtersHelper.decodeRelevanceFilters(location?.query?.filter || '');
            const isWorkModeFilterSelected = decodedFilters.work_mode === 'work_from_home';
            const isRefurbishedFilterSelected = decodedFilters.state === 'refurbished_mobile_phones';
            const isPositionTypeFilterSelected = !!decodedFilters.job_type;

            const sessionFeatures = reqProps?.sessionFeatures || [];

            const jobsH1Flag = sessionFeatures?.includes('olxin-6598');
            const mobileH1Flag = sessionFeatures?.includes('olxin-7414');

            const promises = [
                (Component.fetchData ? Component.fetchData(...args) : Promise.resolve())
            ];

            promises.push(dispatch(getPopularLocationsByLimit(LOCATION_TYPE, LOCATION_LIMIT)));

            return Promise.all(promises).then(() => {
                const state = store.getState();
                const category = categorySelector(state, params.categoryID);
                const categoryFilters = getCategoryFiltersById(categoriesByFilterSelector(state), params.categoryID);
                const itemsMetadata = itemsMetadataSelector(state);

                if (jobsH1Flag || mobileH1Flag) {
                    const filters = { isPositionTypeFilterSelected, isWorkModeFilterSelected, isRefurbishedFilterSelected, decodedFilters };
                    const flags = { jobsH1Flag, mobileH1Flag };

                    const count = getInventoryCount(state?.locations?.selectedLocation, itemsMetadata, categoryFilters);

                    const payload = shouldAddMetadata(filters, category, state?.locations?.selectedLocation, flags)
                        ? { ...renderProps, metadata: { count }, sessionFeatures }
                        : renderProps;

                    return dispatch(
                        getTags(
                            configWrapper(options.config),
                            module,
                            mapPropsToQuery(payload),
                            reqProps
                        )
                    );
                }

                const isL3LocationSelected = state?.locations?.selectedLocation?.type === LOCATION.CITY;
                const isL4LocationSelected = state?.locations?.selectedLocation?.type === LOCATION.NEIGHBOURHOOD;
                const locationFilterFromItemsMetadata = itemsMetadata?.filters?.length > 0 ? itemsMetadata?.filters.find(f => f.id === 'location') || {} : {};
                const locationData = getLocationById(locationFilterFromItemsMetadata, state?.locations?.selectedLocation?.id);
                const aggregatedCount = locationData?.children?.reduce((acc, curr) => acc + curr.count, 0);
                const l4LocationBasedCount = isL4LocationSelected ? locationData?.count : 0;
                const locationBasedCount = isL3LocationSelected ? aggregatedCount : l4LocationBasedCount;
                const totalCount = locationBasedCount || categoryFilters?.count || 0;
                const payload = params?.categoryID === MAIN_CATEGORY.ACS
                    || params?.categoryID === MAIN_CATEGORY.FRIDGE
                    || params?.categoryID === MAIN_CATEGORY.CARS
                    || (params?.categoryID === MAIN_CATEGORY.JOBS && isWorkModeFilterSelected)
                    || (params.categoryID === MAIN_CATEGORY.MOTORCYCLES && (decodedFilters?.price_max || decodedFilters?.price_min))
                    || (isRefurbishedFilterSelected && category?.id === MAIN_CATEGORY.MOBILE_PHONES)
                    ? { ...renderProps, metadata: { count: totalCount }, sessionFeatures }
                    : renderProps;

                return dispatch(
                    getTags(
                        configWrapper(options.config),
                        module,
                        mapPropsToQuery(payload),
                        reqProps
                    )
                );
            });
        }

        static propTypes = {
            __getTags: PropTypes.func.isRequired,
            __title: PropTypes.string,
            __description: PropTypes.string,
            __h1: PropTypes.string,
            __footer: PropTypes.array,
            __seoText: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string
            }),
            __config: PropTypes.shape({
                get: PropTypes.func.isRequired
            }).isRequired,
            __canonical: PropTypes.string,
            module: PropTypes.string,
            metadata: PropTypes.object,
            params: PropTypes.object,
            location: PropTypes.object,
            sessionFeatures: PropTypes.arrayOf(PropTypes.string),
            category: PropTypes.object,
            selectedLocation: PropTypes.object
        };

        fetchData() {
            const { category, location, metadata, params, selectedLocation } = this.props;
            const { categoryID } = params;
            const decodedFilters = filtersHelper.decodeRelevanceFilters(location?.query?.filter || '');
            const isWorkModeFilterSelected = decodedFilters.work_mode === 'work_from_home';
            const isRefurbishedFilterSelected = decodedFilters.state === 'refurbished_mobile_phones';
            const isPositionTypeFilterSelected = !!decodedFilters.job_type;

            const sessionFeatures = this.props?.sessionFeatures || [];
            const jobsH1Flag = sessionFeatures?.includes('olxin-6598');
            const mobileH1Flag = sessionFeatures?.includes('olxin-7414');

            if (jobsH1Flag || mobileH1Flag) {
                const filters = { isPositionTypeFilterSelected, isWorkModeFilterSelected, isRefurbishedFilterSelected, decodedFilters };
                const flags = { jobsH1Flag, mobileH1Flag };

                if (shouldAddMetadata(filters, category, selectedLocation, flags)) {
                    if (metadata?.count > 0) {
                        this.props.__getTags(metadata);
                    }
                }
                else {
                    this.props.__getTags();
                }
            }
            else if (
                category?.id === MAIN_CATEGORY.ACS
                || categoryID === MAIN_CATEGORY.FRIDGE
                || categoryID === MAIN_CATEGORY.CARS
                || (params?.categoryID === MAIN_CATEGORY.JOBS && isWorkModeFilterSelected)
                || (params.categoryID === MAIN_CATEGORY.MOTORCYCLES && (decodedFilters?.price_max || decodedFilters?.price_min))
                || (isRefurbishedFilterSelected && category?.id === MAIN_CATEGORY.MOBILE_PHONES)
            ) {
                if (metadata?.count > 0) {
                    this.props.__getTags(metadata);
                }
            }
            else {
                this.props.__getTags();
            }
        }

        componentDidMount() {
            this.fetchData();
        }

        componentDidUpdate(prevProps) {
            if (prevProps.metadata !== this.props.metadata) {
                this.fetchData();
            }
        }

        render() {
            const {
                __title,
                __description,
                __h1,
                __footer,
                __seoText,
                __canonical,
                __getTags, // eslint-disable-line no-unused-vars
                __config, // eslint-disable-line no-unused-vars
                module,
                ...props
            } = this.props;

            return (
                <React.Fragment>
                    { withHelmet && (
                        <Meta
                            module={ module }
                            title={ __title }
                            description={ __description }
                        />
                    ) }
                    <Component
                        h1={ __h1 }
                        footer={ __footer }
                        seoText={ __seoText }
                        seoTitle={ __title }
                        seoDescription={ __description }
                        seoCanonical={ __canonical }
                        { ...props }
                    />
                </React.Fragment>
            );
        }
    }

    const mapStateToProps = (state, props) => {
        const module = getModule({ ...props, config: props.__config });
        const sessionFeatures = props.sessionFeatures || [];

        const categoryFilters = getCategoryFiltersById(categoriesByFilterSelector(state), props.params.categoryID);
        const { location, params } = props;

        const category = categorySelector(state, params.categoryID);
        const decodedFilters = filtersHelper.decodeRelevanceFilters(location?.query?.filter || '');
        const isWorkModeFilterSelected = decodedFilters.work_mode === 'work_from_home';
        const isRefurbishedFilterSelected = decodedFilters.state === 'refurbished_mobile_phones';
        const isPositionTypeFilterSelected = !!decodedFilters.job_type;

        const jobsH1Flag = sessionFeatures?.includes('olxin-6598');
        const mobileH1Flag = sessionFeatures?.includes('olxin-7414');

        const itemsMetadata = itemsMetadataSelector(state);
        let tags = {};
        let count = 0;
        let totalCount = 0;
        let query;

        if (jobsH1Flag || mobileH1Flag) {
            const filters = { isPositionTypeFilterSelected, isWorkModeFilterSelected, isRefurbishedFilterSelected, decodedFilters };
            const flags = { jobsH1Flag, mobileH1Flag };

            count = getInventoryCount(state?.locations?.selectedLocation, itemsMetadata, categoryFilters);
            query = mapPropsToQuery(
                shouldAddMetadata(filters, category, state?.locations?.selectedLocation, flags)
                    ? { ...props, metadata: { count }, sessionFeatures }
                    : props
            );

            tags = mapStateToTags(
                props.__config,
                state,
                module,
                query
            ) || {};
        }
        else {
            const isL3LocationSelected = state?.locations?.selectedLocation?.type === LOCATION.CITY;
            const isL4LocationSelected = state?.locations?.selectedLocation?.type === LOCATION.NEIGHBOURHOOD;
            const locationFilterFromItemsMetadata = itemsMetadata?.filters?.length > 0 ? itemsMetadata?.filters.find(f => f.id === 'location') || {} : {};
            const locationData = getLocationById(locationFilterFromItemsMetadata, state?.locations?.selectedLocation?.id);
            const aggregatedCount = locationData?.children?.reduce((acc, curr) => acc + curr.count, 0);
            const l4LocationBasedCount = isL4LocationSelected ? locationData?.count : 0;
            const locationBasedCount = isL3LocationSelected ? aggregatedCount : l4LocationBasedCount;

            totalCount = locationBasedCount || categoryFilters?.count || 0;
            query = mapPropsToQuery(
                params?.categoryID === MAIN_CATEGORY.ACS
                || params?.categoryID === MAIN_CATEGORY.FRIDGE
                || params?.categoryID === MAIN_CATEGORY.CARS
                || (params?.categoryID === MAIN_CATEGORY.JOBS && isWorkModeFilterSelected)
                || (params.categoryID === MAIN_CATEGORY.MOTORCYCLES && (decodedFilters?.price_max || decodedFilters?.price_min))
                || (isRefurbishedFilterSelected && category?.id === MAIN_CATEGORY.MOBILE_PHONES)
                    ? { ...props, metadata: { count: totalCount }, sessionFeatures }
                    : props
            );
            tags = mapStateToTags(
                props.__config,
                state,
                module,
                query
            ) || {};
        }

        if (Object.keys(tags)?.length > 0 && JSON.stringify(cache) !== JSON.stringify({ tags, query })) {
            cache = { tags, query };

            return {
                __h1: tags.h1,
                __title: tags.title,
                __description: tags.description,
                __footer: tags.footer,
                __seoText: tags.seoText,
                __canonical: tags.canonical,
                module,
                metadata: { count: totalCount }
            };
        }

        return {
            __h1: cache.tags.h1,
            __title: cache.tags.title,
            __description: cache.tags.description,
            __footer: cache.tags.footer,
            __seoText: cache.tags.seoText,
            __canonical: cache.tags.canonical,
            module,
            metadata: { count: (jobsH1Flag || mobileH1Flag) ? count : totalCount },
            category,
            selectedLocation: state?.locations?.selectedLocation
        };
    };
    const mapDispatchToProps = (dispatch, props) => {
        const module = getModule({ ...props, config: props.__config });

        return {
            __getTags: (metadata = {}) => dispatch(getTags(props.__config, module, mapPropsToQuery({ ...props, metadata })))
        };
    };

    const Mystique = hoistNonReactStatic(
        Object.assign(
            compose(
                withRouter,
                withConfig('__config'),
                withSessionFeature,
                connect(mapStateToProps, mapDispatchToProps)
            )(WithTags),
            {
                displayName: `withTags(${Component.displayName || Component.name})`
            }
        ),
        Component,
        { fetchData: true }
    );

    const FallBack = props => ( // eslint-disable-line react/no-multi-comp
        <React.Fragment>
            { withHelmet && (
                <Meta module={ module } />
            ) }
            <Component { ...props } />
        </React.Fragment>
    );

    return ifConfig(
        ['SEO', 'mystique'],
        { [true]: Mystique },
        hoistNonReactStatic(FallBack, Component)
    );
};

export default withTags;
