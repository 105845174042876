/* global window */

import React from 'react';
import PropTypes from 'prop-types';
import css from './OnBoarding.scss';
import { FormattedMessage as Translation } from 'react-intl';
import Button from 'Components/Button/Button';
import classnames from 'classnames';
import OnBoardingArrow from './OnBoardingArrow';
const MARGIN = 8;
const ARROW_WIDTH = 90;

export default class OnBoarding extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        title: PropTypes.element,
        message: PropTypes.element.isRequired,
        elementNode: PropTypes.object,
        showButton: PropTypes.bool
    };

    static defaultProps={
        showButton: true
    }

    componentDidMount = () => {
        const { elementNode } = this.props;

        if (elementNode) {
            window.scrollTo(0, elementNode.offsetTop - elementNode.offsetHeight);
        }
        if (typeof document !== 'undefined') {
            document.addEventListener('click', this.handleOnClose, true);
        }
    }

    componentWillUnmount = () => {
        if (typeof document !== 'undefined') {
            document.removeEventListener('click', this.handleOnClose, true);
        }
    }

    isAfterMiddle = () => this.props.elementNode && this.props.elementNode.offsetLeft > window.innerWidth / 2;

    handleOnClose = e => {
        e.preventDefault();
        this.props.onClose();
    };

    render() {
        const { elementNode, title, message, showButton } = this.props;
        const overlayStyle = elementNode && {
            top: elementNode.offsetTop - MARGIN,
            left: elementNode.offsetLeft - MARGIN,
            width: elementNode.offsetWidth + (MARGIN * 2),
            height: elementNode.offsetHeight + (MARGIN * 2),
            outlineWidth: document.body.offsetHeight
        };
        const containerStyle = elementNode && {
            top: elementNode.offsetTop + elementNode.offsetHeight + MARGIN,
            left: this.isAfterMiddle() ? elementNode.offsetLeft - ARROW_WIDTH : elementNode.offsetLeft - MARGIN
        };

        return elementNode ? (
            <div className={ css.onBoarding }>
                <div className={ css.overlay } style={ overlayStyle } />
                <div className={ css.contentContainer } style={ containerStyle }>
                    <OnBoardingArrow afterMiddle={ this.isAfterMiddle() } />
                    <div className={ classnames(css.content, { [css.contentAfterMiddle]: this.isAfterMiddle() }) }>
                        { title && <div className={ css.title }>{title}</div>}
                        { message }
                        {!!showButton && <Button type="secondaryBtn" invert className={ css.btnContinue }>
                            <Translation id="featureAdCreditsGetStartedButton" />
                        </Button>}
                    </div>
                </div>
            </div>
        ) : null;
    }
}
