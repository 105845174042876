import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';

import Button from 'Components/Button/Button';
import Icon from 'Components/ThematicIcon/ThematicIcon';

import css from './OpenAppCTA.scss';

export function OpenAppCTA({ icon, iconSize, onClick }) {
    return (
        <div id="menuLocation" className={ css.locationMenu }>
            <div className={ css.openInAppContainer }>
                <Button
                    type="outline"
                    fixed={ true }
                    className={ css.btnOpenInApp }
                    onClick={ onClick }
                    data-aut-id="btnOpenInApp"
                >
                    <Translation id="openAppText" />
                    <div className={ css.btnOpenInAppIcon }>
                        <Icon
                            color="white"
                            data-aut-id="icon-right-arrow"
                            icon={ icon }
                            size={ iconSize }
                        />
                    </div>
                </Button>
            </div>
        </div>
    );
}

OpenAppCTA.propTypes = {
    icon: PropTypes.string.isRequired,
    iconSize: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
};

export default OpenAppCTA;
