/* global window */
import React from 'react';
import css from './SearchHeader.mobile.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchInput from './components/SearchInput';
import IconMenu from './components/IconMenu';
import { FormattedMessage as Translation } from 'react-intl';
import { withFeatureFlag as withLQFeatureFlag } from 'LaquesisEOL/withFeatureFlag';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import LocationMenu from './components/LocationMenu';
import withDirection from 'HOCs/withDirection/withDirection';
import MenuHeader from './components/MenuHeader';
import Auth from '../../../../Auth';
import OnBoarding from 'Components/OnBoarding/OnBoarding';
import cookieManagerHelper from 'Helpers/cookies';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from 'HOCs/withRouter';
import withConfig from 'HOCs/withConfig/withConfig';
import PreNotification from 'Components/PreNotification/PreNotification.mobile';
import MultiLangPickerMobile from 'Components/HeaderWrapper/components/MultiLangPickerMobile/MultiLangPickerMobile';
import { ICONS, LQ_SHORTLIST_FEATURE } from 'Constants/items';
import { Link } from 'panamera-react-ui';
import { THEMES } from 'Constants/bundles';
import {
    __FEATURE_AD_UPGRADE
} from 'Constants/monetizers';
import { VARIANTS } from 'Constants/constants';
import { CHOOSE_PACKAGE_LAQUESIS_FEATURE_FLAG } from 'Helpers/laquesis';
import { withExperiment } from 'LaquesisEOL/withExperiment';
import Button from 'Components/Button/Button';
import { TRACK_EVENT } from 'Constants/tracking';
import withTrack from 'HOCs/withTrack/withTrack';
import OpenAppCTA from 'Components/OpenAppCTA/OpenAppCTA';
import { userIsLogged } from 'Selectors/user';
import setLoginModalState from 'Actions/setLoginModalState';
import { setOpenInApp } from 'Actions/openInApp';

const HIDE_ONBOARDING_LOCATION = 'HIDE_ONBOARDING_LOCATION';
const COOKIE_EXPIRES_DAYS = 1825;

export class SearchHeader extends React.Component {
    static propTypes = {
        enableLocationMenu: PropTypes.bool,
        enableSearchInput: PropTypes.bool,
        params: PropTypes.object,
        router: PropTypes.object,
        preNotificationAvailable: PropTypes.bool,
        preNotificationCookie: PropTypes.bool,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        headerClass: PropTypes.string,
        withSubheader: PropTypes.bool,
        headerLogoRef: PropTypes.object,
        headerRef: PropTypes.object,
        hideHamburger: PropTypes.bool,
        isPaidCampaign: PropTypes.bool,
        categoryId: PropTypes.string,
        seller_state: PropTypes.string,
        variant: PropTypes.string,
        showProfileOptionsDropdown: PropTypes.bool,
        userId: PropTypes.string,
        user: PropTypes.object,
        showHelpButton: PropTypes.bool,
        enableOpenInApp: PropTypes.bool,
        track: PropTypes.func.isRequired,
        openInAppRedirectUrl: PropTypes.string,
        userIsLogged: PropTypes.bool.isRequired,
        setLoginModalState: PropTypes.func.isRequired,
        direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
        isAppNudgeOpen: PropTypes.bool,
        setOpen: PropTypes.func
    };

    static defaultProps = {
        enableLocationMenu: false,
        enableSearchInput: true,
        headerClass: '',
        params: {},
        categoryId: '',
        showProfileOptionsDropdown: false,
        user: {},
        variant: '',
        showHelpButton: false
    };

    constructor(props) {
        super(props);
        const { config } = this.props;

        this.siteCode = config.get('siteCode');
        this.state = {
            openMenu: false,
            openLangModal: false
        };
    }

    handleToggleMenu = () => {
        this.setState(prevState => ({ openMenu: !prevState.openMenu }));
        const { isAppNudgeOpen, setOpen } = this.props;

        if (isAppNudgeOpen) {
            setOpen?.(false);
        }
    };

    handleCloseOnboarding = () => {
        cookieManagerHelper.createCookie(HIDE_ONBOARDING_LOCATION, true, COOKIE_EXPIRES_DAYS);
        this.forceUpdate();
    }

    handleToggleLangModal = () => this.setState(prevState => ({ openLangModal: !prevState.openLangModal }));

    handleHelpClick = () => {
        if (document.getElementById('help')) {
            window.scroll({
                behavior: 'smooth',
                top: document.getElementById('help').offsetTop - 10 // eslint-disable-line no-magic-numbers
            });
        }
    }

    handleClickOpenApp = () => {
        this.props.track(TRACK_EVENT.DETAIL_PAGE_REDIRECT);

        if (this.props?.openInAppRedirectUrl) {
            this.props.router.push(this.props?.openInAppRedirectUrl);
        }
    }
    onClickMyFavorites = e => {
        e.preventDefault();

        if (this.props.userIsLogged) {
            this.props.router.push({
                pathname: '/wishlist'
            });
        }
        else {
            this.props.setLoginModalState({ state: true, redirectTo: '/wishlist', trackProps: { origin: 'wishlist' }});
        }
    };

    render() {
        const { enableLocationMenu, enableSearchInput,
            router, preNotificationAvailable, preNotificationCookie, config,
            headerClass, withSubheader, headerLogoRef, headerRef, hideHamburger,
            isPaidCampaign, categoryId, seller_state,
            showProfileOptionsDropdown, userId, user, params, variant, enableOpenInApp, direction } = this.props;
        const { openMenu, openLangModal } = this.state;
        const multiLangFlag = config.get('enableMultiLang');
        const onBoardingText = <Translation id="onBoardingLocationMessage" />;
        const onBoardingTitle = <Translation id="onBoardingLocationTitle" />;
        const theme = config.get('theme', 'id');
        const isLetGoTheme = theme === THEMES.LETGO;

        const experimentVariant = params.featureType === __FEATURE_AD_UPGRADE && (variant === VARIANTS.VARIANT_B || variant === VARIANTS.VARIANT_C);

        return (
            <header className={ classNames(css.searchHeader, headerClass, {
                [css.withSubheader]: withSubheader,
                [css.shadow]: isPaidCampaign
            }) }
            data-aut-id="defaultHeader">
                {router.getCurrentLocation().pathname === '/'
                    && preNotificationAvailable
                    && !preNotificationCookie
                    && <PreNotification />
                }
                <div className={ classNames(css.headerContainer, { [css.headerContainerFA]: experimentVariant && !isLetGoTheme }) } ref={ headerRef } >
                    <div className={ css.menu }>
                        <IconMenu experimentVariant={ experimentVariant } isOlxAutosLandingPage={ false } categoryId={ categoryId } onClick={ this.handleToggleMenu } openMenu={ openMenu } headerLogoRef={ headerLogoRef } hideHamburger={ hideHamburger } isPaidCampaign={ isPaidCampaign } seller_state={ seller_state } showProfileOptionsDropdown={ showProfileOptionsDropdown } userId={ userId } user={ user } />
                        {enableLocationMenu && !openMenu && (
                            <div
                                id="menuLocation"
                                className={ classNames(css.locationMenu) }
                                ref={ el => (this.elementNode = el) }
                            >
                                <LocationMenu />
                                {cookieManagerHelper.isCookieEnabled() && !cookieManagerHelper.readCookie(HIDE_ONBOARDING_LOCATION) && (
                                    <OnBoarding
                                        elementNode={ this.elementNode }
                                        onClose={ this.handleCloseOnboarding }
                                        title={ onBoardingTitle }
                                        message={ onBoardingText } />
                                )}
                            </div>
                        )}
                        {enableOpenInApp && (
                            <OpenAppCTA icon={ ICONS.ARROW_RIGHT } iconSize={ 9 } onClick={ this.handleClickOpenApp } />
                        )}
                        {!!this.props.showHelpButton
                            && <Button
                                key="help"
                                type="tertiaryBtn"
                                data-aut-id="btnLogin"
                                onClick={ this.handleHelpClick }
                                className={ classNames(css.buttons) }
                            >
                                <Translation id="help" />
                            </Button>}
                    </div>
                    <div className={ css.searchInputWrapper }>
                        {enableSearchInput && <SearchInput />}
                        {enableSearchInput && <Link
                            key="wishlist"
                            to="/wishlist"
                            className={ classNames(css.icons, css.favorites) }
                            onClick={ this.onClickMyFavorites }
                            rel="nofollow"
                            data-aut-id="btnWishlist"
                        >
                            <NewIconButton
                                name="favorite"
                                icon="favoriteOff"
                                direction={ direction }
                            />
                        </Link>}

                    </div>
                </div>
                <MenuHeader
                    open={ openMenu }
                    user={ Auth.getUser() }
                    onClose={ this.handleToggleMenu }
                    toggleLangModal={ this.handleToggleLangModal }
                    isOlxAutosLandingPage={ false }
                />
                {multiLangFlag
                    && <MultiLangPickerMobile open={ openLangModal } onClose={ this.handleToggleLangModal } />
                }

            </header>
        );
    }
}

const mapStateToProps = state => ({
    preNotificationAvailable: state.preNotification.preNotificationAvailable,
    preNotificationCookie: state.preNotification.preNotificationCookie,
    userIsLogged: userIsLogged(state),
    isAppNudgeOpen: !!state.openInApp?.isOpen
});

export const mapDispatchToProps = dispatch => {
    return {
        setOpen: data => dispatch(setOpenInApp(data)),
        setLoginModalState: state => dispatch(setLoginModalState(state))
    };
};

export default compose(
    withRouter,
    withDirection,
    withConfig,
    withTrack,
    withLQFeatureFlag(LQ_SHORTLIST_FEATURE),
    withExperiment(CHOOSE_PACKAGE_LAQUESIS_FEATURE_FLAG),
    connect(mapStateToProps, mapDispatchToProps)
)(SearchHeader);
