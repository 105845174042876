import React from 'react';
import classNames from 'classnames';
import css from './SearchInput.scss';
import PropTypes from 'prop-types';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import AsyncOverlaySearch from 'Components/OverlaySearch/AsyncOverlaySearch';
import { decodeSearch } from 'Helpers/strings';
import withTrack from 'HOCs/withTrack/withTrack';
import withRouter from 'HOCs/withRouter';
import { compose } from 'redux';
import {
    selectedLocationSelector,
    selectedLocationNameSelector,
    isCurrentGPSLocationSelector
} from 'Selectors/location';
import { connect } from 'react-redux';
import SearchLabel from './SearchLabel';
import { injectIntl } from 'react-intl';
import { buildObjectFromURL } from 'Helpers/url';
import { SEARCH_START } from 'Constants/tracking';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { THEMES } from 'Constants/bundles';
import { getCategoryTree } from 'Reducers/componentHelpers';
import { getCategoriesForTracking } from 'Selectors/categories';
import { getQuickFiltersByName } from 'Selectors/filtersTanak';

export class SearchInput extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object,
        trackOrigin: PropTypes.string,
        track: PropTypes.func.isRequired,
        selectedLocation: PropTypes.object,
        category: PropTypes.object,
        isCurrentGPSLocation: PropTypes.bool,
        selectedLocationName: PropTypes.string.isRequired,
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        categoryTreeSelected: PropTypes.object,
        quickFilters: PropTypes.string
    };

    static defaultProps = {
        location: {},
        trackOrigin: '',
        selectedLocation: {}
    }

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        const { marketConfig, category } = props;

        this.categoryId = marketConfig && marketConfig.get('adpvAuto', 'carCategory');
        this.isOlxIdCarListingPage = this.categoryId === (category && category.id) && (marketConfig && marketConfig.get('showOlxAutosSearchInputOnCarListingPage'));
        this.theme = marketConfig && marketConfig.get('theme', 'id');
    }

    getQuerySearch = () => {
        const { pathname } = this.props.location;
        const { search } = buildObjectFromURL(pathname);

        return search || '';
    }

    getSearchTerm = () => {
        const querySearch = this.getQuerySearch();

        if (!querySearch && this.props.category) {
            return this.props.category.name;
        }

        return querySearch && decodeSearch(querySearch);
    }

    handleOnOpenToggleSearch = () => {
        const { trackOrigin, categoryTreeSelected, quickFilters = {}} = this.props;

        const categoryTree = getCategoriesForTracking(categoryTreeSelected);

        this.props.track(SEARCH_START, {
            origin: trackOrigin,
            resultset_type: trackOrigin,
            ...categoryTree,
            ...quickFilters
        });
        this.setState({ isOpen: true });
    }

    handleOnCloseToggleSearch = () => this.setState({ isOpen: false, temporalTerm: undefined });

    render() {
        const { location, selectedLocation, selectedLocationName, isCurrentGPSLocation } = this.props;
        const searchTerm = this.getSearchTerm();
        const isHome = location.pathname === '/';
        const locationType = selectedLocation && selectedLocation.type;

        return (
            <React.Fragment>
                <div data-aut-id="btnSearch"
                    className={ classNames(css.searchInput, {
                        [css.thinBorder]: this.theme === THEMES.LETGO
                    }) }
                    onClick={ this.handleOnOpenToggleSearch }
                >
                    {!(this.isOlxIdCarListingPage) && <Icon
                        icon="search"
                        size={ 20 }
                    />
                    }
                    <div className={ css.searchText }>
                        <SearchLabel
                            locationType={ locationType }
                            locationName={ selectedLocationName }
                            searchTerm={ searchTerm }
                            isHome={ isHome }
                            isCurrentGPSLocation={ isCurrentGPSLocation }
                        />
                    </div>
                    {(this.isOlxIdCarListingPage)
                        && <div className={ css.searchIcon }>
                            <Icon
                                icon="search"
                                size={ 16 }
                                color="white"
                            />
                        </div>
                    }
                </div>
                { this.state.isOpen
                    && <AsyncOverlaySearch
                        onClose={ this.handleOnCloseToggleSearch }
                        defaultTerm={ searchTerm }
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => ({
    selectedLocation: selectedLocationSelector(state),
    category: state.categories.elements[props.params.categoryID],
    selectedLocationName: selectedLocationNameSelector(state, props),
    isCurrentGPSLocation: isCurrentGPSLocationSelector(state),
    trackOrigin: state.track.origin,
    categoryTreeSelected: getCategoryTree(
        state?.categories?.elements,
        props.params?.categoryID
    ),
    quickFilters: getQuickFiltersByName(state, props.params?.categoryID)
});

export default compose(
    withRouter,
    withTrack,
    injectIntl,
    withConfig('marketConfig'),
    connect(mapStateToProps)
)(SearchInput);
