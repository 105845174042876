import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage as Translation } from 'react-intl';
import setToastMessageState from 'Actions/setToastMessageState';

export class CopyToClipboard extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.array
        ]).isRequired,
        value: PropTypes.string.isRequired,
        setToastMessageState: PropTypes.func.isRequired
    };

    input = React.createRef();
    styles = {
        opacity: '0',
        position: 'absolute',
        zIndex: -10000
    };

    onCopyToClipboard = () => {
        this.input.current.select();
        document.execCommand('copy');
        this.props.setToastMessageState({ isOpen: true, message: <Translation id="copiedToClipboard" />, expiration: 5000 });
    };

    render() {
        const { children, value } = this.props;

        return (
            <React.Fragment>
                { children({
                    onCopyToClipboard: this.onCopyToClipboard
                })}
                <input
                    ref={ this.input }
                    defaultValue={ value }
                    style={ this.styles }
                />
            </React.Fragment>
        );
    }
}

export const mapDispatchToProps = ({
    setToastMessageState: state => dispatch => dispatch(setToastMessageState(state))
});

export default connect(null, mapDispatchToProps)(CopyToClipboard);
