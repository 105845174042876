import { isMobile } from './devices';

/* global navigator*/
export const handleShare = (shareText, fallbackCallback) => {
    if (navigator.share && isMobile) {
        navigator.share({
            text: shareText
        }).catch(() => {
            fallbackCallback();
        });
    }
    else {
        fallbackCallback();
    }
};
