/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './LocationMenu.scss';
import { connect } from 'react-redux';
import { selectedLocationNameSelector, isCurrentGPSLocationSelector } from 'Selectors/location';
import withRouter from 'HOCs/withRouter';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { LayoutHelper } from 'olx-autos-landing-page';
import { getLocationsById } from 'Actions/locations';
import { updateLocationHeader } from 'Actions/categoryBrowsing';
import { setGPS, setLocationGps } from 'Actions/locations';
import { updateLocation, resetLocation } from 'Actions/selectLocation';
import withTrack from 'HOCs/withTrack/withTrack';
import { FullPageLoader } from 'panamera-react-ui';
import SelectLocation from 'Components/SelectLocation/SelectLocation';
import { LOCATION_COMPLETE, HOME } from 'Constants/tracking';
import { withConfig } from 'HOCs/withConfig/withConfig';
import classNames from 'classnames';
import { LOCATION_ICON_SIZE } from 'Constants/header';
import { decodeSearch } from 'Helpers/strings';
import { categorySelector, categoryIdSelector, getCategoriesForTracking } from 'Selectors/categories';
import { locationByHomeAndLatLong } from 'Helpers/locations';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { getCategoryTree } from 'Reducers/componentHelpers';

export class LocationMenu extends Component {
    static propTypes = {
        selectedLocationName: PropTypes.string.isRequired,
        updateLocationHeader: PropTypes.func,
        setGPS: PropTypes.func.isRequired,
        setLocationGps: PropTypes.func.isRequired,
        trackOrigin: PropTypes.string,
        track: PropTypes.func.isRequired,
        intl: PropTypes.object.isRequired,
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        router: PropTypes.shape({
            push: PropTypes.func,
            location: PropTypes.object
        }),
        location: PropTypes.shape({
            query: PropTypes.object,
            id: PropTypes.string,
            gps: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            type: PropTypes.string
        }).isRequired,
        params: PropTypes.object,
        categoryTreeSelected: PropTypes.object
    }

    static defaultProps = {
        trackOrigin: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isLoading: false
        };
        this.hideCurrentLocationName = props.marketConfig && props.marketConfig.get('hideCurrentLocationName');
        this.urlKeywords = props.marketConfig.get('olxAutos', 'urlKeywords');
    }

    handleLoading = isLoading => this.setState({ isLoading });

    handleIsOpen = isOpen => this.setState({ isOpen });

    handleClick = () => this.props.updateLocationHeader(this.handleLoading, this.handleIsOpen);

    renderLoading() {
        if (this.state.isLoading) {
            const text = this.props.intl.formatMessage({ id: 'loadingAdsToRelocate' });

            return (
                <FullPageLoader
                    className={ css.loader }
                    text={ text }
                />
            );
        }
        return null;
    }

    handleLocationChange = (location, locationProps) => {
        const {
            params,
            trackOrigin,
            setGPS,
            setLocationGps,
            marketConfig: config,
            categoryTreeSelected
        } = this.props;

        const searchTerm = decodeSearch(params.text);
        const categoryTree = getCategoriesForTracking(categoryTreeSelected);

        if (location && location.id) {
            this.props.track(LOCATION_COMPLETE, {
                origin: trackOrigin,
                place_selected_id: location.id,
                select_from: HOME,
                ...locationProps,
                ...LayoutHelper.getAcquisitionChannelTrackingValues(),
                ...categoryTree
            });
        }
        this.handleIsOpen(false);

        locationByHomeAndLatLong(location, this.props, searchTerm, config, setGPS, setLocationGps);

        window.scrollTo(0, 0);
        return null;
    };

    onClose = () => this.handleIsOpen(false);

    render() {
        const staticAsseturl = this.props.marketConfig && this.props.marketConfig.get('staticAssets');

        return (
            <React.Fragment>
                { this.renderLoading() }
                <SelectLocation
                    open={ this.state.isOpen }
                    onChange={ this.handleLocationChange }
                    onClose={ this.onClose }
                    trackingOrigin={ HOME }
                >
                    <div className={ classNames(css.locationMenu) } onClick={ this.handleClick }>
                        {this.hideCurrentLocationName && <Icon icon="location" size={ LOCATION_ICON_SIZE.SIXTEEN } />}
                        {!this.hideCurrentLocationName && <Icon icon="location" size={ LOCATION_ICON_SIZE.EIGHTTEEN } />}
                        <div className={ classNames(
                            css.locationText,
                            css.marginIcons,
                            {
                                [css.locationNameText]: !this.hideCurrentLocationName
                            }
                        ) }>
                            { this.props.selectedLocationName }
                        </div>
                        <ImageWrapper
                            fileName={ `${staticAsseturl}/external/base/img/arrow-down.svg` }
                            className={ css.arrowImg }
                            alt="arrow-down"
                        />
                    </div>
                </SelectLocation>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const categoryId = categoryIdSelector(state, props.params);

    return {
        trackOrigin: state.track.origin,
        selectedLocationName: selectedLocationNameSelector(state, props),
        selectedCategory: categorySelector(state, categoryId),
        isCurrentGPSLocation: isCurrentGPSLocationSelector(state),
        categoryTreeSelected: getCategoryTree(
            state?.categories?.elements,
            categoryId
        )
    };
};

const mapDispatchToProps = ({ getLocationsById, updateLocationHeader, setGPS, setLocationGps, updateLocation, resetLocation });

export default compose(
    withRouter,
    withTrack,
    injectIntl,
    withConfig('marketConfig'),
    connect(mapStateToProps, mapDispatchToProps)
)(LocationMenu);
